import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Box, Container, Flex, Heading } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageContentZone = function PageContentZone(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Container, _extends({
    py: 5,
    px: 2
  }, props), ___EmotionJSX(Flex, {
    flexDirection: "column"
  }, children));
};
export var HeaderZone = function HeaderZone(_ref2) {
  var children = _ref2.children;
  return ___EmotionJSX(Box, null, ___EmotionJSX(Heading.h1, {
    px: {
      xs: 0,
      md: 3
    }
  }, children));
};
export var PaginationZone = function PaginationZone(_ref3) {
  var children = _ref3.children;
  return ___EmotionJSX(Flex, {
    justifyContent: "center",
    pt: 8,
    pb: 12
  }, children);
};