import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { emitLinkingModalAction, emitLinkingModalImpression } from "@catchoftheday/analytics";
import { CustomerContext } from "@catchoftheday/customer";
import { legacyEventBus, TRIGGER_ONEPASS_LINKING_MODAL_EVENT, triggerOnePassLinkingToast, useEventBus } from "@catchoftheday/state";
import { date } from "@catchoftheday/utilities";
import { ONEPASS_LINK_QUERY_PARAM_KEY } from "../../../consts";
import { generateOnePassLinkingUrl } from "../../../utilities";
import { LinkingModal } from "../../pure/LinkingModal";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var LinkingModalContainer = function LinkingModalContainer() {
  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var _useState2 = useState(""),
      originalUrl = _useState2[0],
      setOriginalUrl = _useState2[1];

  var _useState3 = useState({}),
      originalQueryParams = _useState3[0],
      setOriginalQueryParams = _useState3[1];

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data,
      loading = _useContext.loading;

  var _ref = customer || {},
      membership = _ref.membership;

  var isLinked = useMemo(function () {
    return !!(membership !== null && membership !== void 0 && membership.linked);
  }, [membership === null || membership === void 0 ? void 0 : membership.linked]);
  var analyticsAction = useMemo(function () {
    return customer !== null && customer !== void 0 && customer.id ? isLinked ? "Linking Exist Modal" : "Linking Modal" : null;
  }, [isLinked, customer === null || customer === void 0 ? void 0 : customer.id]); // TODO: This is just a temporary fix for OP linking issue where linking modal shows up again for newly created customers
  // that was created by clicking "Login with OnePass" in auth0 login page.
  // We need to think of better way to detect if the customer is newly created or not.

  var isNewlyCreatedCustomer = useCallback(function () {
    var customerCreatedAt = customer === null || customer === void 0 ? void 0 : customer.createdAt;
    if (!customerCreatedAt) return false;
    var now = new Date();
    var timeDifference = date.parseTimeDifference(now, new Date(customerCreatedAt));

    if (timeDifference) {
      if (timeDifference.unit === "s") {
        return true;
      } else if (timeDifference.unit === "m" && timeDifference.value <= 3) {
        return true;
      }
    }

    return false;
  }, [customer === null || customer === void 0 ? void 0 : customer.createdAt]);

  var closeModal = function closeModal() {
    if (analyticsAction) {
      emitLinkingModalAction({
        action: analyticsAction,
        label: "cancel"
      });
    }

    setIsOpen(false);
  };

  var eventHandler = function eventHandler(payload) {
    if (true) {
      var url = new URL(payload.originalUrl, window.location.origin);
      setOriginalUrl(url.pathname);

      if (url.search.length > 0) {
        setOriginalQueryParams(queryString.parse(url.search));
      }

      setIsOpen(true);
    }
  };

  useEventBus(TRIGGER_ONEPASS_LINKING_MODAL_EVENT, function (event) {
    if (event.payload) {
      eventHandler(event.payload);
    }
  });
  useEffect(function () {
    legacyEventBus === null || legacyEventBus === void 0 ? void 0 : legacyEventBus.on(TRIGGER_ONEPASS_LINKING_MODAL_EVENT.type, eventHandler);
    return function () {
      legacyEventBus === null || legacyEventBus === void 0 ? void 0 : legacyEventBus.detach(TRIGGER_ONEPASS_LINKING_MODAL_EVENT.type, eventHandler);
    };
  }, []);
  useEffect(function () {
    if (true) {
      var _window, _window$location;

      var queryParams = queryString.parse((_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.search);

      if ((queryParams === null || queryParams === void 0 ? void 0 : queryParams[ONEPASS_LINK_QUERY_PARAM_KEY]) === "1") {
        var _window2, _window2$location, _customer$membership;

        queryParams === null || queryParams === void 0 ? true : delete queryParams[ONEPASS_LINK_QUERY_PARAM_KEY];
        setOriginalQueryParams(queryParams);
        setOriginalUrl((_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$location = _window2.location) === null || _window2$location === void 0 ? void 0 : _window2$location.pathname);
        var shouldHideModal = !customer || !!(customer !== null && customer !== void 0 && (_customer$membership = customer.membership) !== null && _customer$membership !== void 0 && _customer$membership.linked);

        if (!isNewlyCreatedCustomer() && shouldHideModal) {
          triggerOnePassLinkingToast({
            isSuccess: false
          });
          return;
        }

        setIsOpen(!shouldHideModal);
      }
    }
  }, [customer]);
  useEffect(function () {
    if (isOpen && analyticsAction) {
      emitLinkingModalImpression(analyticsAction);
    }
  }, [isOpen, analyticsAction]);
  var ctaUrl = generateOnePassLinkingUrl(originalUrl, originalQueryParams);
  return ___EmotionJSX(LinkingModal, {
    isOpen: isOpen,
    loading: loading,
    isLinked: isLinked,
    onClose: closeModal,
    email: customer === null || customer === void 0 ? void 0 : customer.email,
    ctaUrl: ctaUrl,
    onProceed: function onProceed() {
      if (analyticsAction) {
        emitLinkingModalAction({
          action: analyticsAction,
          label: "proceed"
        });
      }
    }
  });
};