import React from "react";
import { MetaTags, MetaTitle } from "@catchoftheday/common-vendors";
import { capitaliseFirstLetter } from "@catchoftheday/utilities";
import { CataloguePageType, STATIC_META_DESCRIPTION } from "../../../consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CataloguePageMetaTags = function CataloguePageMetaTags(_ref) {
  var host = _ref.host,
      pageType = _ref.pageType,
      _ref$searchQuery = _ref.searchQuery,
      searchQuery = _ref$searchQuery === void 0 ? "" : _ref$searchQuery,
      _ref$sellerName = _ref.sellerName,
      sellerName = _ref$sellerName === void 0 ? "" : _ref$sellerName,
      _ref$metaTitle = _ref.metaTitle,
      metaTitle = _ref$metaTitle === void 0 ? "" : _ref$metaTitle,
      _ref$metaDescription = _ref.metaDescription,
      metaDescription = _ref$metaDescription === void 0 ? "" : _ref$metaDescription;

  if (pageType === CataloguePageType.SEARCH) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MetaTitle, {
      host: host
    }, "Search for ".concat(capitaliseFirstLetter(searchQuery))), ___EmotionJSX(MetaTags, {
      withHead: true,
      tags: [{
        name: "robots",
        content: "noindex,nofollow"
      }, {
        name: "description",
        content: STATIC_META_DESCRIPTION
      }]
    }));
  }

  if (pageType === CataloguePageType.SELLER) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MetaTitle, {
      host: host
    }, "Products sold by ".concat(sellerName)), ___EmotionJSX(MetaTags, {
      withHead: true,
      tags: [{
        name: "description",
        content: STATIC_META_DESCRIPTION
      }]
    }));
  }

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MetaTitle, {
    host: host
  }, metaTitle), ___EmotionJSX(MetaTags, {
    withHead: true,
    tags: [{
      name: "description",
      content: metaDescription
    }]
  }));
};