import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["mobileFacetsOpen"];

var _templateObject;

import React from "react";
import { Box, Container, Flex, IconOnePass } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageLayoutZone = function PageLayoutZone(props) {
  return ___EmotionJSX(Container, _extends({
    px: {
      xs: 0,
      md: 3
    },
    flexDirection: "column",
    position: "relative"
  }, props));
};
export var HeadingZone = function HeadingZone(props) {
  return ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    justifyContent: "space-between",
    mt: {
      xs: 0,
      md: 6
    },
    pl: {
      xs: 2,
      md: 0
    }
  }, props));
};
export var BannerZone = function BannerZone(props) {
  return ___EmotionJSX(Box, _extends({
    display: "grid",
    gridAutoColumns: "1fr",
    justifyContent: "center",
    mt: {
      md: 4
    },
    rowGap: 4
  }, props));
};
var FacetModal = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: opacity 0.3s, top 0.3s ease-in-out;\n"])));
export var FacetsZone = function FacetsZone(_ref) {
  var mobileFacetsOpen = _ref.mobileFacetsOpen,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(FacetModal, _extends({
    opacity: {
      xs: mobileFacetsOpen ? "1" : "0",
      md: "1"
    },
    pointerEvents: {
      xs: mobileFacetsOpen ? "auto" : "none",
      md: "auto"
    },
    position: {
      xs: "fixed",
      md: "relative"
    },
    zIndex: {
      xs: 50,
      md: "auto"
    },
    overflow: {
      xs: "auto",
      md: "visible"
    },
    top: {
      xs: mobileFacetsOpen ? "0px" : "10px",
      md: "auto"
    },
    right: {
      xs: "0px",
      md: "auto"
    },
    left: {
      xs: "0px",
      md: "auto"
    },
    bottom: {
      xs: "0px",
      md: "auto"
    },
    backgroundColor: "ui.backgroundColor"
  }, props), ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "330px"
    }
  }, props.children));
};
export var ProductsZone = function ProductsZone(props) {
  return ___EmotionJSX(Box, _extends({
    flexGrow: 1,
    pl: {
      _: 2,
      md: 6
    },
    pr: {
      _: 2,
      md: 0
    }
  }, props));
};
export var ResultsZone = function ResultsZone(props) {
  return ___EmotionJSX(Flex, props);
};
export var PaginationZone = function PaginationZone(props) {
  return ___EmotionJSX(Flex, _extends({
    justifyContent: "center",
    mb: 12
  }, props));
};
export var StickyHeader = function StickyHeader(props) {
  return ___EmotionJSX(Flex, _extends({
    justifyContent: "space-between",
    mb: "24px",
    width: {
      xs: "100%",
      md: "250px"
    },
    height: {
      xs: "40px",
      md: "auto"
    },
    alignItems: "center",
    position: {
      xs: "sticky",
      md: "absolute"
    },
    top: {
      xs: "70px",
      md: "30px"
    },
    right: {
      xs: "auto",
      md: "0px"
    },
    background: {
      xs: "white",
      md: "none"
    },
    style: {
      zIndex: 11
    },
    boxShadow: {
      xs: "0 0 5px 5px rgba(0, 0, 0, 0.15)",
      md: "none"
    }
  }, props));
};
export var SortOptionZone = function SortOptionZone(props) {
  return ___EmotionJSX(Flex, _extends({
    width: {
      xs: "50%",
      md: "auto"
    },
    justifyContent: "center",
    display: {
      xs: "flex",
      md: "block"
    }
  }, props));
};
export var MobileFacetHeaderZone = function MobileFacetHeaderZone(props) {
  return ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
    backgroundColor: "secondary",
    boxShadow: "2px 2px 5px 0 rgba(0, 0, 0, 0.5)",
    display: {
      xs: "flex",
      md: "none"
    },
    position: "sticky",
    top: "0",
    style: {
      zIndex: 11
    }
  }, props));
};
export var MobileFacetFooterZone = function MobileFacetFooterZone(props) {
  return ___EmotionJSX(Flex, _extends({
    position: "sticky",
    zIndex: 1,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: "center",
    height: "68px",
    padding: 3,
    backgroundColor: "white",
    boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.5)",
    display: {
      xs: "flex",
      md: "none"
    }
  }, props));
};
export var NoResultsZone = function NoResultsZone(props) {
  return ___EmotionJSX(PageLayoutZone, null, ___EmotionJSX(Box, _extends({
    my: 5,
    width: "100%"
  }, props)));
};
export var OnePassIcon = function OnePassIcon() {
  return ___EmotionJSX(IconOnePass, {
    "aria-label": "OnePass",
    width: "60px",
    height: "14px",
    mr: "4px",
    mt: "-2px",
    color: "onePass.primary"
  });
};