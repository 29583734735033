import { filterObject } from "@catchoftheday/utilities";
var supportedSponsoredFacets = ["f.group_id", "f.brand", "f.price", "f.average_rating", "f.delivery", "f.sold_by"];
export function hasUnsupportedSponsoredFacets(queryObject) {
  return Object.keys(queryObject).some(function (key) {
    var isFacet = key.startsWith("f.");
    return isFacet && !supportedSponsoredFacets.includes(key);
  });
}

function getRatingFromString(rating) {
  if (!rating) {
    return;
  }

  var innerString = rating.slice(1, -1);
  var parts = innerString.split("-");
  var firstPart = parts[0].replace(/"/g, "");
  var number = parseInt(firstPart);
  return number;
}

function isSoldByFacet(value) {
  return value === "CATCH" || value === "MARKETPLACE";
}

function isDeliveryFacet(value) {
  return value === "ONEPASS" || value === "FREE" || value === "EXPRESS";
}

export function mapSponsoredFacets(queryObject) {
  var _queryObject$fGroup_, _toArray$0$split, _toArray, _toArray$, _toArray2, _toArray3;

  var toArray = function toArray(value) {
    return typeof value === "string" ? [value] : value !== null && value !== void 0 ? value : null;
  };

  var groupId = typeof queryObject["f.group_id"] === "string" ? queryObject["f.group_id"] : (_queryObject$fGroup_ = queryObject["f.group_id"]) === null || _queryObject$fGroup_ === void 0 ? void 0 : _queryObject$fGroup_[0];
  var category = groupId === null || groupId === void 0 ? void 0 : groupId.split("/").pop();
  var prices = (_toArray$0$split = (_toArray = toArray(queryObject["f.price"])) === null || _toArray === void 0 ? void 0 : (_toArray$ = _toArray[0]) === null || _toArray$ === void 0 ? void 0 : _toArray$.split("-")) !== null && _toArray$0$split !== void 0 ? _toArray$0$split : [];
  var brandIds = toArray(queryObject["f.brand"]);
  var minPrice = parseInt(prices[0]) || undefined;
  var maxPrice = parseInt(prices[1]) || undefined;
  var rating = typeof queryObject["f.average_rating"] === "string" ? getRatingFromString(queryObject["f.average_rating"]) : undefined;
  var delivery = (_toArray2 = toArray(queryObject["f.delivery"])) === null || _toArray2 === void 0 ? void 0 : _toArray2.map(function (item) {
    return item.toUpperCase();
  }).filter(isDeliveryFacet);
  var soldBy = (_toArray3 = toArray(queryObject["f.sold_by"])) === null || _toArray3 === void 0 ? void 0 : _toArray3.map(function (item) {
    return item.toUpperCase();
  }).filter(isSoldByFacet);
  return filterObject({
    category: category,
    brandIds: brandIds,
    minPrice: minPrice,
    maxPrice: maxPrice,
    rating: rating,
    delivery: delivery,
    soldBy: soldBy
  });
}