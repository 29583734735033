import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["productItems", "lazyLoad", "sponsoredItemsTracking", "positionOffset", "displayAdultBlur", "placeholderProps"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { emitTrackingPixel } from "@catchoftheday/analytics";
import { Box } from "@catchoftheday/cg-components";
import { ProductCardPlaceholder } from "@catchoftheday/common-vendors";
import { ProductCard } from "../ProductCard";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductGrid = function ProductGrid(_ref) {
  var productItems = _ref.productItems,
      lazyLoad = _ref.lazyLoad,
      sponsoredItemsTracking = _ref.sponsoredItemsTracking,
      _ref$positionOffset = _ref.positionOffset,
      positionOffset = _ref$positionOffset === void 0 ? 0 : _ref$positionOffset,
      _ref$displayAdultBlur = _ref.displayAdultBlur,
      displayAdultBlur = _ref$displayAdultBlur === void 0 ? false : _ref$displayAdultBlur,
      placeholderProps = _ref.placeholderProps,
      layoutProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, _extends({
    display: "grid",
    gridTemplateColumns: {
      sm: "repeat(2, 1fr)",
      lg: "repeat(3, 1fr)",
      xl: "repeat(4, 1fr)"
    },
    columnGap: {
      xs: 2,
      md: 5
    },
    rowGap: {
      xs: 2,
      md: 5
    }
  }, layoutProps), productItems.map(function (productItem, index) {
    if (productItem === null) {
      return ___EmotionJSX(Box, {
        key: "product-card-placeholder-".concat(index),
        width: "100%",
        pb: "20px",
        flexGrow: 1,
        px: {
          xs: "2px",
          md: "10px"
        }
      }, ___EmotionJSX(ProductCardPlaceholder, _extends({
        uniqueKey: "product-card-placeholder-".concat(index),
        minWidth: "200px",
        minHeight: "443px",
        "aria-label": "Product card placeholder"
      }, placeholderProps)));
    }

    var sourcePosition = positionOffset + (index + 1);
    return ___EmotionJSX(ProductCard, {
      key: productItem.isSponsored ? "".concat(productItem.id, "-sponsored") : "".concat(productItem.id, "-").concat(sourcePosition),
      lazyLoad: index < 4 ? false : lazyLoad,
      productItem: _objectSpread(_objectSpread({}, productItem), {}, {
        sourcePosition: sourcePosition
      }),
      width: "100%",
      minHeight: "400px",
      px: "0px",
      height: "auto",
      onProductClick: function onProductClick() {
        if (sponsoredItemsTracking !== null && sponsoredItemsTracking !== void 0 && sponsoredItemsTracking[productItem.id]) {
          emitTrackingPixel(sponsoredItemsTracking[productItem.id].clickTrackingUrl);
        }
      },
      onProductSeen: function onProductSeen() {
        if (sponsoredItemsTracking !== null && sponsoredItemsTracking !== void 0 && sponsoredItemsTracking[productItem.id]) {
          emitTrackingPixel(sponsoredItemsTracking[productItem.id].impressionTrackingUrl);
        }
      },
      displayAdultBlur: displayAdultBlur
    });
  }));
};