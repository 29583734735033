import React from "react";
import { Heading } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageTitle = function PageTitle(_ref) {
  var title = _ref.title;
  return ___EmotionJSX(Heading.h1, {
    fontSize: {
      xs: "lg",
      md: "2xl",
      lg: "3xl"
    }
  }, title);
};