import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import shouldForwardProp from "@styled-system/should-forward-prop";
import { alignItems, background, color, compose, cursorProp, layout, position, space, styled, textTransformProp, themeGet, typography, visibilityProp } from "@catchoftheday/theme";
export var textButtonProps = compose(visibilityProp, cursorProp, space, layout, background, position, color, typography, textTransformProp, alignItems);
export var TextButton = styled("button", {
  shouldForwardProp: shouldForwardProp
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: none;\n  padding: 0;\n  background: transparent;\n  color: ", ";\n  cursor: pointer;\n  &:hover {\n    text-decoration: underline;\n  }\n  ", "\n"])), themeGet("colors.secondary"), textButtonProps);
TextButton.displayName = "TextButton";
export default TextButton;