import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["productId", "title", "url", "containerProps"];
import React from "react";
import { Flex, Heading, Link, Truncate } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductCardTitle = function ProductCardTitle(_ref) {
  var productId = _ref.productId,
      title = _ref.title,
      url = _ref.url,
      containerProps = _ref.containerProps,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Flex, _extends({
    position: "relative",
    flexShrink: 0,
    justifyContent: "center"
  }, containerProps), ___EmotionJSX(Truncate, {
    lines: 2
  }, ___EmotionJSX(Heading.h2, _extends({
    m: 0,
    fontSize: "base",
    fontWeight: "normal"
  }, props), url ? ___EmotionJSX(Link, {
    color: "text",
    hoverColor: "text",
    href: url,
    title: "View ".concat(title),
    "data-product-id": productId
  }, title) : title)));
};