import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { useBreakpoint } from "@catchoftheday/theme";
import { HeadingZone, SortOptionZone, StickyHeader } from "../../layouts/PageLayout/PageLayout";
import { PageTitle } from "../../pure/Header/PageTitle/PageTitle";
import { ToggleFacetsButton } from "../../pure/Header/ToggleFacetsButton/ToggleFacetsButton";
import { SearchSortOptions } from "../../pure/SearchSortOptions/SearchSortOptions";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderContainer = function HeaderContainer(_ref) {
  var _ref$pageTitle = _ref.pageTitle,
      pageTitle = _ref$pageTitle === void 0 ? "" : _ref$pageTitle,
      sortOptions = _ref.sortOptions,
      _ref$totalFacetsAppli = _ref.totalFacetsApplied,
      totalFacetsApplied = _ref$totalFacetsAppli === void 0 ? 0 : _ref$totalFacetsAppli,
      banner = _ref.banner;

  var _useBreakpoint = useBreakpoint({
    minusOnePx: true
  }),
      isMobile = _useBreakpoint.isMobile;

  if (isMobile) {
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(StickyHeader, null, ___EmotionJSX(ToggleFacetsButton, {
      totalFacetsApplied: totalFacetsApplied
    }), sortOptions && ___EmotionJSX(SortOptionZone, null, ___EmotionJSX(SearchSortOptions, {
      options: sortOptions
    }))), banner && ___EmotionJSX(React.Fragment, null, banner), ___EmotionJSX(HeadingZone, null, ___EmotionJSX(PageTitle, {
      title: pageTitle
    })));
  }

  return ___EmotionJSX(React.Fragment, null, banner && ___EmotionJSX(React.Fragment, null, banner), ___EmotionJSX(Box, {
    width: "100%",
    position: "relative"
  }, ___EmotionJSX(StickyHeader, null, ___EmotionJSX(ToggleFacetsButton, {
    totalFacetsApplied: totalFacetsApplied
  }), sortOptions && ___EmotionJSX(SortOptionZone, null, ___EmotionJSX(SearchSortOptions, {
    options: sortOptions
  }))), ___EmotionJSX(HeadingZone, null, ___EmotionJSX(PageTitle, {
    title: pageTitle
  }))));
};