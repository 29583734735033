import React, { useContext, useEffect, useState } from "react";
import { emitLinkingNotification, emitUpsellNotification } from "@catchoftheday/analytics";
import { Link } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { legacyEventBus, TRIGGER_ONEPASS_LINKING_TOAST_EVENT, useEventBus } from "@catchoftheday/state";
import { URL_ONEPASS_HELP_CENTRE_SUBMIT_REQUEST } from "@header/consts";
import { getQueryStringValue } from "@header/utilities";
import { showToast } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var useOnePassNotification = function useOnePassNotification() {
  var _membership$subscript6, _membership$subscript7;

  var _useContext = useContext(CustomerContext),
      customer = _useContext.data;

  var _useState = useState(false),
      manualToast = _useState[0],
      setManualToast = _useState[1];

  var _useState2 = useState(false),
      manualToastSuccess = _useState2[0],
      setManualToastSuccess = _useState2[1];

  var _ref = customer || {},
      membership = _ref.membership,
      club = _ref.club;

  var showMessageQueryParam = getQueryStringValue("showmessage");

  var generateToastData = function generateToastData(isSuccessToast) {
    if (isSuccessToast) {
      var _membership$subscript, _membership$subscript2, _membership$subscript3, _membership$subscript4, _membership$subscript5;

      var message = "";

      if (!(club !== null && club !== void 0 && club.active) && !(membership !== null && membership !== void 0 && (_membership$subscript = membership.subscription) !== null && _membership$subscript !== void 0 && _membership$subscript.active)) {
        message = "Catch and OnePass accounts linked. No active subscription found.";
      } else if (!(club !== null && club !== void 0 && club.active) && membership !== null && membership !== void 0 && (_membership$subscript2 = membership.subscription) !== null && _membership$subscript2 !== void 0 && _membership$subscript2.active && membership !== null && membership !== void 0 && (_membership$subscript3 = membership.subscription) !== null && _membership$subscript3 !== void 0 && _membership$subscript3.onFreeTrial) {
        message = showMessageQueryParam === "l" ? "Catch and OnePass accounts linked." : "OnePass free trial activated.";
      } else if (!(club !== null && club !== void 0 && club.active) && membership !== null && membership !== void 0 && (_membership$subscript4 = membership.subscription) !== null && _membership$subscript4 !== void 0 && _membership$subscript4.active && !(membership !== null && membership !== void 0 && membership.subscription.onFreeTrial)) {
        message = showMessageQueryParam === "l" ? "Catch and OnePass accounts linked." : "OnePass subscription activated.";
      } else if (club !== null && club !== void 0 && club.active && !(membership !== null && membership !== void 0 && (_membership$subscript5 = membership.subscription) !== null && _membership$subscript5 !== void 0 && _membership$subscript5.active) && showMessageQueryParam === "l") {
        message = "Catch and OnePass accounts linked.";
      }

      return {
        isError: false,
        message: message,
        toastMessage: message
      };
    } else {
      return {
        isError: true,
        message: "Accounts could not be linked",
        toastMessage: ___EmotionJSX(React.Fragment, null, "There was a problem in linking your OnePass account.", ___EmotionJSX("br", null), "Please submit an", " ", ___EmotionJSX(Link, {
          href: URL_ONEPASS_HELP_CENTRE_SUBMIT_REQUEST,
          target: "_blank",
          color: "inherit",
          textDecoration: "underline"
        }, "enquiry with OnePass for assistance"), ".")
      };
    }
  };

  var eventHandler = function eventHandler(payload) {
    if (true) {
      var isSuccessToast = payload.isSuccess;
      setManualToast(true);
      setManualToastSuccess(isSuccessToast);
    }
  };

  useEventBus(TRIGGER_ONEPASS_LINKING_TOAST_EVENT, function (event) {
    if (event.payload) {
      eventHandler(event.payload);
    }
  });
  useEffect(function () {
    legacyEventBus === null || legacyEventBus === void 0 ? void 0 : legacyEventBus.on(TRIGGER_ONEPASS_LINKING_TOAST_EVENT.type, eventHandler);
    return function () {
      legacyEventBus === null || legacyEventBus === void 0 ? void 0 : legacyEventBus.detach(TRIGGER_ONEPASS_LINKING_TOAST_EVENT.type, eventHandler);
    };
  }, []);
  useEffect(function () {
    if (customer && (manualToast || showMessageQueryParam === "u" || showMessageQueryParam === "l")) {
      var toastData = generateToastData(manualToast ? manualToastSuccess : !!(membership !== null && membership !== void 0 && membership.linked));

      var _ref2 = toastData || {},
          isError = _ref2.isError,
          message = _ref2.message,
          toastMessage = _ref2.toastMessage;

      if (message) {
        if (showMessageQueryParam === "u") {
          emitUpsellNotification({
            type: isError ? "error" : "success",
            message: message
          });
        } else if (showMessageQueryParam === "l") {
          emitLinkingNotification({
            type: isError ? "error" : "success",
            message: message
          });
        }

        showToast({
          message: toastMessage,
          isError: isError,
          options: typeof toastMessage !== "string" ? {
            autoClose: false,
            closeButton: true
          } : {}
        });
      }
    }
  }, [club === null || club === void 0 ? void 0 : club.active, customer, membership === null || membership === void 0 ? void 0 : membership.linked, membership === null || membership === void 0 ? void 0 : (_membership$subscript6 = membership.subscription) === null || _membership$subscript6 === void 0 ? void 0 : _membership$subscript6.active, membership === null || membership === void 0 ? void 0 : (_membership$subscript7 = membership.subscription) === null || _membership$subscript7 === void 0 ? void 0 : _membership$subscript7.onFreeTrial, showMessageQueryParam, manualToast, manualToastSuccess]);
};