import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useCallback, useEffect, useState } from "react";
import { localStorage } from "@catchoftheday/utilities";
import { RECENT_SEARCH_TERMS_KEY, SEARCH_SUGGESTION_INDEX_NAME } from "../../../../consts";
import { getSuggestedSearchTerms, getTrendingSearchTerms } from "../../../../utilities/searchBar/getSearchTermsFromSection";
import { getSearchTermSuggestionSection } from "../../../../utilities/searchBar/getSearchTermSuggestionSection.js";
import { RecentSearchTerms } from "../../../pure/SearchBar/RecentSearchTerms/RecentSearchTerms";
import { SearchSuggestions } from "../../../pure/SearchBar/SearchSuggestions/SearchSuggestions";
import { jsx as ___EmotionJSX } from "@emotion/react";

function renderSearchSuggestions(_ref) {
  var autocompleteItems = _ref.autocompleteItems,
      getItemProps = _ref.getItemProps,
      getSectionProps = _ref.getSectionProps;
  return ___EmotionJSX(SearchSuggestions, _extends({
    data: autocompleteItems.data,
    getItemProps: getItemProps,
    title: autocompleteItems.displayName || ("indexSectionName" in autocompleteItems && autocompleteItems.indexSectionName ? autocompleteItems.indexSectionName : SEARCH_SUGGESTION_INDEX_NAME)
  }, getSectionProps(autocompleteItems)));
}

export var SearchTermsContainer = function SearchTermsContainer(_ref2) {
  var query = _ref2.query,
      autocompleteItems = _ref2.autocompleteItems,
      getItemProps = _ref2.getItemProps,
      getSectionProps = _ref2.getSectionProps;

  var _useState = useState([]),
      recentSearchTerms = _useState[0],
      setRecentSearchTerms = _useState[1];

  var getRecentSearches = useCallback(function () {
    var storedSearches = localStorage.getItem(RECENT_SEARCH_TERMS_KEY) || "[]";
    var storedSearchesArray = JSON.parse(storedSearches).filter(Boolean);

    if (storedSearchesArray.length) {
      var terms = storedSearchesArray.map(function (recentSearch) {
        if (typeof recentSearch !== "string") {
          return recentSearch.term;
        } else {
          return recentSearch;
        }
      });
      setRecentSearchTerms(terms);
    }
  }, []);

  function clearAll() {
    localStorage.removeItem(RECENT_SEARCH_TERMS_KEY);
    setRecentSearchTerms([]);
  }

  function clearItem(termToRemove) {
    var remaining = recentSearchTerms.filter(function (term) {
      return term !== termToRemove;
    });
    localStorage.setItem(RECENT_SEARCH_TERMS_KEY, JSON.stringify(remaining));
    setRecentSearchTerms(remaining);
  }

  useEffect(function () {
    getRecentSearches();
  }, [getRecentSearches]);

  if (autocompleteItems !== null && autocompleteItems !== void 0 && autocompleteItems.length) {
    var trendingSearchTerms = getTrendingSearchTerms(autocompleteItems);
    var suggestedSearchTerms = getSuggestedSearchTerms(autocompleteItems);

    if (!recentSearchTerms.length && trendingSearchTerms !== null && trendingSearchTerms !== void 0 && trendingSearchTerms.data.length) {
      return renderSearchSuggestions({
        autocompleteItems: trendingSearchTerms,
        getItemProps: getItemProps,
        getSectionProps: getSectionProps
      });
    }

    if (suggestedSearchTerms !== null && suggestedSearchTerms !== void 0 && suggestedSearchTerms.data.length) {
      return renderSearchSuggestions({
        autocompleteItems: suggestedSearchTerms,
        getItemProps: getItemProps,
        getSectionProps: getSectionProps
      });
    }
  } // Render section with the search term input as a suggestion if a query is present and no search suggestions are available


  if (query) {
    var _getSearchTermSuggest = getSearchTermSuggestionSection(query),
        section = _getSearchTermSuggest.section,
        getSearchTermItemProps = _getSearchTermSuggest.getItemProps;

    return renderSearchSuggestions({
      autocompleteItems: section,
      getItemProps: getSearchTermItemProps,
      getSectionProps: function getSectionProps() {
        return {};
      }
    });
  }

  if (recentSearchTerms.length > 0 && !query) {
    return ___EmotionJSX(RecentSearchTerms, {
      recentSearchTerms: recentSearchTerms,
      clearAll: clearAll,
      clearItem: clearItem
    });
  }

  return null;
};