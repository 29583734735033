import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useEffect } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { useInitAuthFlow, useRefreshAccessToken } from "@catchoftheday/auth";
import { Box, Hide } from "@catchoftheday/cg-components";
import { CustomerContext, CustomerContextProvider } from "@catchoftheday/customer";
import { hideOnAndroidWebView } from "@catchoftheday/utilities";
import { CouponNotification } from "@header/components/CouponNotification";
import { MegaMenu } from "@header/components/MegaMenu";
import { PrimaryHeader } from "@header/components/PrimaryHeader";
import { TopHeader } from "@header/components/TopHeader";
import { useHeaderConfigurationQuery } from "@header/generated/graphql";
import { useSetDeviceCookie } from "@header/hooks/useSetDeviceCookie";
import { HeaderContext } from "../../context";
import { HeaderContentWrapper } from "./HeaderContentWrapper";
import { useHeaderMetaData } from "./metadata";
import { jsx as ___EmotionJSX } from "@emotion/react";

var HeaderInternal = function HeaderInternal(_ref) {
  var _metaData$flybuysNoti, _metaData$unreadMarke;

  var onSearchChange = _ref.onSearchChange,
      _ref$host = _ref.host,
      host = _ref$host === void 0 ? "" : _ref$host,
      hideCartBadge = _ref.hideCartBadge,
      hideMegaMenu = _ref.hideMegaMenu,
      _ref$ssrMegaMenu = _ref.ssrMegaMenu,
      ssrMegaMenu = _ref$ssrMegaMenu === void 0 ? true : _ref$ssrMegaMenu,
      stickyRef = _ref.stickyRef;
  // Refresh access token if applicable and is expired
  useRefreshAccessToken(); // Set device cookie to access server side

  useSetDeviceCookie();

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["adults_only_shop_menu_feb_24", "web_tactical_category_page_nov_23"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 2),
      adultOnlyShopExperiment = _getExperiments$exper[0],
      tacticalCategoryExperiment = _getExperiments$exper[1],
      experimentsLoading = _getExperiments.loading;

  var _useHeaderConfigurati = useHeaderConfigurationQuery({
    variables: {
      domain: host,
      showAdultShopMenu: (adultOnlyShopExperiment === null || adultOnlyShopExperiment === void 0 ? void 0 : adultOnlyShopExperiment.bucket) === 1,
      isTacticalCategoryExperiment: (tacticalCategoryExperiment === null || tacticalCategoryExperiment === void 0 ? void 0 : tacticalCategoryExperiment.bucket) !== 0
    },
    ssr: ssrMegaMenu,
    // Block call until experiments are loaded
    skip: !!experimentsLoading
  }),
      data = _useHeaderConfigurati.data;

  var _useContext2 = useContext(CustomerContext),
      customer = _useContext2.data;

  var _ref2 = customer || {},
      flybuys = _ref2.flybuys;

  var headerConfiguration = data === null || data === void 0 ? void 0 : data.configuration.header;

  var _useHeaderMetaData = useHeaderMetaData(headerConfiguration),
      metaData = _useHeaderMetaData.metaData,
      initialHeaderMetaData = _useHeaderMetaData.initialHeaderMetaData,
      isLoading = _useHeaderMetaData.isLoading; // Init new auth flow


  useInitAuthFlow();
  var cartItemCount = hideCartBadge ? undefined : metaData === null || metaData === void 0 ? void 0 : metaData.cartItemCount; // Required for Constructor user tracking https://docs.constructor.io/integration/tracking/#logged-in-user-id

  useEffect(function () {
    if (customer !== null && customer !== void 0 && customer.metaData.customerUuid) {
      window.cnstrcUserId = customer.metaData.customerUuid;
    }
  }, [customer]);
  return ___EmotionJSX(HeaderContext.Provider, {
    value: {
      onSearchChange: onSearchChange
    }
  }, ___EmotionJSX(HeaderContentWrapper, {
    stickyRef: stickyRef,
    isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn,
    topHeaderChildren: ___EmotionJSX(React.Fragment, null, !isLoading && (metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn) && metaData.couponNotificationEnabled && ___EmotionJSX(CouponNotification, {
      displayName: metaData === null || metaData === void 0 ? void 0 : metaData.displayName
    }), ___EmotionJSX(Hide, {
      xs: true,
      sm: true
    }, ___EmotionJSX(TopHeader, {
      isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn,
      headerConfiguration: headerConfiguration
    })))
  }, ___EmotionJSX(PrimaryHeader, {
    id: "primary-header",
    isLoading: isLoading,
    isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn,
    isCCMember: metaData === null || metaData === void 0 ? void 0 : metaData.clubActive,
    isFlybuysLinked: flybuys === null || flybuys === void 0 ? void 0 : flybuys.isLinked,
    userName: metaData === null || metaData === void 0 ? void 0 : metaData.displayName,
    cartItemsAmount: cartItemCount,
    wishItemsAmount: metaData === null || metaData === void 0 ? void 0 : metaData.wishlistItemCount,
    myAccountMessageCount: metaData === null || metaData === void 0 ? void 0 : metaData.messageCount,
    headerConfiguration: headerConfiguration,
    host: host
  }), !hideMegaMenu && ___EmotionJSX(Box, {
    height: {
      xs: "auto",
      md: "50px"
    }
  }, (initialHeaderMetaData === null || initialHeaderMetaData === void 0 ? void 0 : initialHeaderMetaData.navData) && ___EmotionJSX(MegaMenu, {
    id: "mega-menu",
    shops: initialHeaderMetaData.navData,
    essentialsMenu: initialHeaderMetaData.essentialsMenu,
    accountMenu: _objectSpread(_objectSpread({}, initialHeaderMetaData.accountMenu), {}, {
      flybuysNotificationCount: (_metaData$flybuysNoti = metaData === null || metaData === void 0 ? void 0 : metaData.flybuysNotificationCount) !== null && _metaData$flybuysNoti !== void 0 ? _metaData$flybuysNoti : 0,
      unreadMarketplaceMessageCount: (_metaData$unreadMarke = metaData === null || metaData === void 0 ? void 0 : metaData.unreadMarketplaceMessageCount) !== null && _metaData$unreadMarke !== void 0 ? _metaData$unreadMarke : 0
    }),
    cartItemsCount: cartItemCount,
    wishlistItemsCount: metaData === null || metaData === void 0 ? void 0 : metaData.wishlistItemCount,
    isLoggedIn: metaData === null || metaData === void 0 ? void 0 : metaData.loggedIn
  }))));
};

var HeaderWithCustomer = function HeaderWithCustomer(props) {
  return ___EmotionJSX(CustomerContextProvider, null, ___EmotionJSX(HeaderInternal, props));
};

export var Header = hideOnAndroidWebView(HeaderWithCustomer);