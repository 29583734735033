import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useCallback, useEffect, useState } from "react";
import { useSessionStorage } from "@catchoftheday/utilities";
import { CATEGORY_FACET_SHOW_MORE_LIMIT } from "../../../../consts";
import { FacetAccordion } from "../FacetAccordion/FacetAccordion";
import { CategoryFacetList } from "./CategoryFacetList"; // Import the new component

import { jsx as ___EmotionJSX } from "@emotion/react";
var showMoreLimit = CATEGORY_FACET_SHOW_MORE_LIMIT;

var selectedCategoryIndex = function selectedCategoryIndex(categories) {
  return categories.findIndex(function (value) {
    return value.selected;
  });
};

var hasShowMore = function hasShowMore(categories) {
  return categories.length >= showMoreLimit && categories.findIndex(function (value) {
    return value.selected;
  }) < showMoreLimit;
};

var updateShowMoreValues = function updateShowMoreValues(options) {
  var newValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  options.forEach(function (_ref) {
    var subId = _ref.id,
        children = _ref.children;
    newValues[subId] = !!children && hasShowMore(children);

    if (children && children.length) {
      updateShowMoreValues(children, newValues);
    }
  });
  return newValues;
};

export var CategoryFacet = function CategoryFacet(_ref2) {
  var categories = _ref2.categories;
  var displayName = categories.displayName,
      options = categories.options;

  var _useSessionStorage = useSessionStorage("visitedCategories"),
      _useSessionStorage2 = _slicedToArray(_useSessionStorage, 2),
      visitedCategories = _useSessionStorage2[0],
      setVisitedCategories = _useSessionStorage2[1];

  var _useState = useState(selectedCategoryIndex(options) > -1 ? 1 : 0),
      hasSelected = _useState[0],
      setHasSelected = _useState[1];

  var newShowMoreValues = updateShowMoreValues(options);
  newShowMoreValues.top = hasShowMore(options);

  var _useState2 = useState(newShowMoreValues),
      showMoreMap = _useState2[0],
      setShowMoreMap = _useState2[1];

  var checkSelected = useCallback(function (categories) {
    return categories.some(function (category) {
      return category.selected || category.children && checkSelected(category.children);
    });
  }, []);
  useEffect(function () {
    setHasSelected(checkSelected(options) ? 1 : 0);
  }, [options, checkSelected]);
  useEffect(function () {
    var newShowMoreValues = updateShowMoreValues(options);
    newShowMoreValues.top = hasShowMore(options);
    setShowMoreMap(newShowMoreValues);
  }, [options]);
  var updateVisited = useCallback(function (categoryId) {
    if (!visitedCategories.includes(categoryId)) {
      setVisitedCategories([].concat(_toConsumableArray(visitedCategories), [categoryId]));
    }
  }, [visitedCategories, setVisitedCategories]);
  return ___EmotionJSX(FacetAccordion, {
    id: "group_id",
    displayName: displayName,
    totalApplied: hasSelected
  }, ___EmotionJSX(CategoryFacetList, {
    categories: options,
    parentId: "top",
    isRoot: true,
    showMoreMap: showMoreMap,
    setShowMoreMap: setShowMoreMap,
    visitedCategories: visitedCategories,
    updateVisited: updateVisited
  }));
};