import React, { useContext, useEffect, useState } from "react";
import { Pagination as CGCPagination } from "@catchoftheday/cg-components";
import { scrollToTop } from "@catchoftheday/utilities";
import { CatalogueContext } from "../../../contexts/CatalogueContext.jsx";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Pagination = function Pagination(_ref) {
  var pagination = _ref.pagination;

  var _useState = useState(""),
      urlPath = _useState[0],
      setUrlPath = _useState[1];

  var _useContext = useContext(CatalogueContext),
      addFilterValue = _useContext.addFilterValue;

  var page = pagination.page,
      totalPages = pagination.totalPages;
  useEffect(function () {
    setUrlPath(window.location.href);
  }, []);
  return ___EmotionJSX(CGCPagination, {
    onPageClick: function onPageClick(page) {
      addFilterValue("page", "".concat(page), false);
      scrollToTop();
    },
    urlPath: urlPath,
    pageCurrent: page || 1,
    pageTotal: totalPages || 1,
    parameter: "page",
    fontSize: [10, 12, 14]
  });
};