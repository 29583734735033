import React, { useContext } from "react";
import { Box, Flex, List, NoSSR, NotificationBadge } from "@catchoftheday/cg-components";
import { ACCOUNT_MENU_ICON_MAP } from "../../consts";
import { MenuContentPopup } from "../MenuContentPopup";
import { MenuHeader } from "../MenuHeader";
import { MenuItem } from "../MenuItem";
import { MobileMenuContext } from "../MobileMenuContext";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AccountMenu = function AccountMenu(_ref) {
  var isOpen = _ref.isOpen;

  var _useContext = useContext(MobileMenuContext),
      accountMenu = _useContext.accountMenu,
      isLoggedIn = _useContext.isLoggedIn;

  if (!accountMenu) {
    return null;
  }

  return ___EmotionJSX(MenuContentPopup, {
    "aria-label": "Account menu",
    isOpen: isOpen,
    cover: true
  }, ___EmotionJSX(MenuHeader, {
    title: "Account",
    bg: "secondary",
    color: "white"
  }), ___EmotionJSX(Box, {
    as: "nav",
    overflowY: "scroll",
    maxHeight: "100%"
  }, ___EmotionJSX(List, {
    pt: "16px",
    mb: "0px"
  }, (isLoggedIn ? accountMenu.loggedInMenu : accountMenu.loggedOutMenu).map(function (menuItem, index) {
    var IconSet = ACCOUNT_MENU_ICON_MAP.find(function (item) {
      return item.name === menuItem.name;
    });
    var isHelpCentre = menuItem.name === "Help Centre";
    var isLogout = menuItem.name === "Logout";
    return ___EmotionJSX(MenuItem, {
      key: "".concat(menuItem.name, "-").concat(index),
      url: menuItem.url,
      highlight: isHelpCentre || isLogout,
      fontWeight: "medium",
      borderTop: isLogout ? "0px" : undefined
    }, IconSet && (IconSet === null || IconSet === void 0 ? void 0 : IconSet.icon) && ___EmotionJSX(Flex, {
      width: "18px",
      mr: "10px",
      justifyContent: "center"
    }, menuItem.name === "Flybuys" && accountMenu.flybuysNotificationCount > 0 && ___EmotionJSX(NoSSR, null, ___EmotionJSX(NotificationBadge, {
      "aria-label": "Flybuys count",
      text: "",
      top: "13px",
      left: "10px",
      height: "10px",
      width: "10px",
      minWidth: "0"
    })), menuItem.name === "Messages" && accountMenu.unreadMarketplaceMessageCount > 0 && ___EmotionJSX(NoSSR, null, ___EmotionJSX(NotificationBadge, {
      "aria-label": "Marketplace messages count",
      text: accountMenu.unreadMarketplaceMessageCount,
      top: "10px",
      left: "10px",
      lineHeight: "14px",
      width: "14px",
      minWidth: "0",
      fontSize: "2xs"
    })), ___EmotionJSX(IconSet.icon, {
      width: IconSet.shrink ? "14px" : "18px",
      height: IconSet.shrink ? "14px" : "18px",
      title: menuItem.name
    })), menuItem.name);
  }))));
};