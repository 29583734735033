import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useRef } from "react";
import { useBrandRecommendedProductsQuery } from "@personalisation/generated/graphql";
import { emitRecommenderRequest, SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_RESTRICTED_BRAND, SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_RESTRICTED_SELLER } from "@catchoftheday/analytics";
import { RecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData, handleRecommenderQueryUpdate, useVisibleRecommenderTiles } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BrandRecommendedProducts = function BrandRecommendedProducts(_ref) {
  var productIds = _ref.productIds,
      brandName = _ref.brandName,
      sellerName = _ref.sellerName,
      fromProduct = _ref.fromProduct,
      sourceId = _ref.sourceId,
      newProductCard = _ref.newProductCard;
  var visibleTiles = useVisibleRecommenderTiles();
  var trackingVariables = useRef({
    offset: 0,
    limit: visibleTiles
  });
  var sourceType = sellerName ? SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_RESTRICTED_SELLER : SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_RESTRICTED_BRAND;

  var _useBrandRecommendedP = useBrandRecommendedProductsQuery({
    variables: {
      productIds: productIds,
      limit: visibleTiles,
      offset: 0
    },
    skip: !visibleTiles,
    ssr: false,
    notifyOnNetworkStatusChange: true,
    onCompleted: function onCompleted(_ref2) {
      var _ref2$brandRecommende = _ref2.brandRecommendedProducts.metaData,
          preHydrationCount = _ref2$brandRecommende.preHydrationCount,
          postHydrationCount = _ref2$brandRecommende.postHydrationCount,
          hydrationErrorCount = _ref2$brandRecommende.hydrationErrorCount;
      emitRecommenderRequest(_objectSpread({
        source_type: sourceType,
        pre_hydration_product_count: preHydrationCount,
        post_hydration_product_count: postHydrationCount,
        hydration_error_count: hydrationErrorCount,
        page_offset: trackingVariables.current.offset,
        page_limit: trackingVariables.current.limit
      }, fromProduct));
    }
  }),
      data = _useBrandRecommendedP.data,
      error = _useBrandRecommendedP.error,
      loading = _useBrandRecommendedP.loading,
      fetchMore = _useBrandRecommendedP.fetchMore;

  if (!(data !== null && data !== void 0 && data.brandRecommendedProducts.products.length) || error) {
    return null;
  }

  var products = data.brandRecommendedProducts.products;
  var _data$brandRecommende = data.brandRecommendedProducts.pagination,
      totalCount = _data$brandRecommende.totalCount,
      nextItemIndex = _data$brandRecommende.nextItemIndex;
  var tiles = getProductTiles({
    products: products,
    sourceType: sourceType,
    sourceId: sourceId,
    fromProduct: fromProduct,
    newProductCard: newProductCard
  });
  var trackingData = getRecommenderTrackingData(products, "product");
  return ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    heading: sellerName ? "Similar ".concat(sellerName, " Products") : "".concat(brandName, " Accessories"),
    sourceType: sourceType,
    sourceId: sourceId,
    fromProduct: fromProduct,
    trackingData: trackingData,
    paginate: true,
    maxTiles: totalCount,
    carouselProps: {
      onSlideChange: function onSlideChange(_ref3) {
        var currentIndex = _ref3.currentIndex,
            slidesPerView = _ref3.slidesPerView,
            direction = _ref3.direction;
        var lastVisibleTile = currentIndex + slidesPerView - 1;

        if (direction === "forward" && !products[lastVisibleTile]) {
          trackingVariables.current = {
            offset: nextItemIndex,
            limit: slidesPerView
          };
          fetchMore({
            variables: {
              offset: nextItemIndex
            },
            updateQuery: function updateQuery(prevResult, _ref4) {
              var fetchMoreResult = _ref4.fetchMoreResult;
              return handleRecommenderQueryUpdate({
                prevResult: prevResult,
                fetchMoreResult: fetchMoreResult,
                recommenderKey: "brandRecommendedProducts"
              });
            }
          });
        }
      },
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading
    }
  });
};