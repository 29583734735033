import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { graphql } from "msw";
import { OFFER_ID_THAT_WILL_THROW_ERROR } from "../../constants/literals";
import { mockCart, mockCartMarketplaceParcelItems, mockCartOrderSummary, mockCartParcel, mockCartParcelItems, mockRecommendationParcelItem } from "./mockCart";
export var handler = graphql.mutation("AddToCart", function (req, res, ctx) {
  var offerId = req.variables.offerId;
  var otherParcels = mockCartParcel.slice(1);

  if (offerId === OFFER_ID_THAT_WILL_THROW_ERROR) {
    return res(ctx.data({
      addToCart: {
        __typename: "UserError",
        message: "Item is out of stock."
      }
    }));
  }

  var modifiedCart = _objectSpread(_objectSpread({}, mockCart), {}, {
    itemsQuantitySum: 5,
    parcels: [_objectSpread(_objectSpread({}, mockCartParcel[0]), {}, {
      items: [].concat(_toConsumableArray(mockCartParcelItems), [mockRecommendationParcelItem]),
      subGroups: [_objectSpread(_objectSpread({}, mockCartParcel[0].subGroups[0]), {}, {
        items: [].concat(_toConsumableArray(mockCartParcelItems), [mockRecommendationParcelItem])
      })]
    })].concat(_toConsumableArray(otherParcels)),
    orderSummary: _objectSpread(_objectSpread({}, mockCartOrderSummary), {}, {
      itemSummary: {
        items: [].concat(_toConsumableArray(mockCartParcelItems), [mockRecommendationParcelItem], _toConsumableArray(mockCartMarketplaceParcelItems)),
        total: {
          amount: 20000,
          currency: "AUD"
        }
      },
      subtotalPrice: {
        __typename: "Money",
        amount: 20000
      },
      totalPrice: {
        __typename: "Money",
        amount: 18500,
        currency: "AUD"
      }
    })
  });

  return res(ctx.data({
    addToCart: modifiedCart
  }));
});