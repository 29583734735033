import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["offerId", "quantity", "imageUrl", "title", "setError", "setLoading", "children", "sourceId", "sourceType", "sourcePosition", "eventId", "redirectUrl", "onClick", "noToast", "isFormValid", "getValues"];
import React, { useCallback } from "react";
import { getSourcePlatform } from "@catchoftheday/analytics";
import { Button } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BuyNowButton = function BuyNowButton(_ref) {
  var _props$variant;

  var offerId = _ref.offerId,
      quantity = _ref.quantity,
      imageUrl = _ref.imageUrl,
      title = _ref.title,
      setError = _ref.setError,
      setLoading = _ref.setLoading,
      children = _ref.children,
      sourceId = _ref.sourceId,
      sourceType = _ref.sourceType,
      sourcePosition = _ref.sourcePosition,
      eventId = _ref.eventId,
      redirectUrl = _ref.redirectUrl,
      onClick = _ref.onClick,
      noToast = _ref.noToast,
      isFormValid = _ref.isFormValid,
      getValues = _ref.getValues,
      props = _objectWithoutProperties(_ref, _excluded);

  var onBuyNow = useCallback(function () {
    var _eventId$toString, _quantity$toString, _sourceType$toString;

    setLoading === null || setLoading === void 0 ? void 0 : setLoading(true);
    var data = {
      eventId: (_eventId$toString = eventId === null || eventId === void 0 ? void 0 : eventId.toString()) !== null && _eventId$toString !== void 0 ? _eventId$toString : "",
      offerId: offerId,
      quantity: (_quantity$toString = quantity.toString()) !== null && _quantity$toString !== void 0 ? _quantity$toString : 1,
      sourcePlatform: getSourcePlatform(),
      sourceId: sourceId !== null && sourceId !== void 0 ? sourceId : "",
      sourceType: (_sourceType$toString = sourceType === null || sourceType === void 0 ? void 0 : sourceType.toString()) !== null && _sourceType$toString !== void 0 ? _sourceType$toString : "",
      sourcePosition: typeof sourcePosition === "undefined" ? "" : sourcePosition.toString()
    };
    location.assign(redirectUrl + "?" + new URLSearchParams(data).toString());
  }, [redirectUrl, sourceId, sourceType, sourcePosition, offerId, eventId, setLoading, quantity]);
  return ___EmotionJSX(Button, _extends({
    variant: (_props$variant = props.variant) !== null && _props$variant !== void 0 ? _props$variant : "plain",
    sizeVariant: "sm",
    p: 0,
    onClick: onBuyNow,
    "aria-label": "Buy Now",
    color: "secondary",
    textTransform: "initial",
    fontSize: {
      xs: "xs",
      md: "base"
    },
    fontWeight: "normal",
    underlineOnHover: true
  }, props), children);
};