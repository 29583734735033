import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useContext, useEffect, useState } from "react";
import { AppExperimentContext, SOURCE_TYPE_PRODUCT_PAGE_PERSONALISED_RECOMMENDER, SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_EVENTS_V2 } from "@catchoftheday/analytics";
import { Box } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { getSalesChannel, isBot as isBotFunc, isSoldByCatch } from "@catchoftheday/utilities";
import { BrandRecommendedProducts } from "../BrandRecommendedProducts";
import { PeopleAlsoViewed } from "../PeopleAlsoViewed";
import { PersonalisedProductRecommender } from "../PersonalisedProductRecommender";
import { RecommendedEvents } from "../RecommendedEvents";
import { SimilarProducts } from "../SimilarProducts";
import { SponsoredProductRecommender } from "../SponsoredProductRecommender";
import { showRecommenderForBrandSlug, showRecommenderForSellerId } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
var RecommenderWrapper = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  clear: both;\n  overflow: hidden;\n"])), function (props) {
  return props.isNewCard ? "> div {\n        background: ".concat(themeGet("ui.backgroundColor"), ";\n      }") : "> div:nth-of-type(even) {\n        background: ".concat(themeGet("ui.backgroundColor"), ";\n      }\n      > div:nth-of-type(odd) {\n        background: white;\n      }");
});
export var RecommenderContainer = function RecommenderContainer(_ref) {
  var _offer$seller, _offer$seller2, _offer$seller3;

  var brand = _ref.brand,
      offer = _ref.offer,
      productId = _ref.productId,
      adult = _ref.adult,
      fromProduct = _ref.fromProduct,
      sourceId = _ref.sourceId,
      category = _ref.category,
      host = _ref.host;

  var _useState = useState(false),
      isBot = _useState[0],
      setIsBot = _useState[1];

  var isCOTD = !!host && getSalesChannel(host) === "COTD";
  useEffect(function () {
    setIsBot(isBotFunc());
  }, []);
  var isBrandRestrictedRecommender = brand && isSoldByCatch(offer === null || offer === void 0 ? void 0 : (_offer$seller = offer.seller) === null || _offer$seller === void 0 ? void 0 : _offer$seller.id) && showRecommenderForBrandSlug(brand.slug);

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["similar_product_recommendation_experiment", "personalised_recommender_on_product_page_may_22", "product_card_redesign_jul23", "retail_media_product_page_recommender_oct_23"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 4),
      similarProductExperiment = _getExperiments$exper[0],
      personalisedProductExperiment = _getExperiments$exper[1],
      productCardExperiment = _getExperiments$exper[2],
      retailMediaRecommenderExperiment = _getExperiments$exper[3];

  var isSellerRestrictedRecommender = (offer === null || offer === void 0 ? void 0 : offer.seller) && showRecommenderForSellerId(offer === null || offer === void 0 ? void 0 : (_offer$seller2 = offer.seller) === null || _offer$seller2 === void 0 ? void 0 : _offer$seller2.id);
  var isRestrictedRecommender = isBrandRestrictedRecommender || isSellerRestrictedRecommender;
  var isNewProductCard = (productCardExperiment === null || productCardExperiment === void 0 ? void 0 : productCardExperiment.bucket) === 1;
  return ___EmotionJSX(RecommenderWrapper, {
    isNewCard: isNewProductCard
  }, !isBot && isCOTD && !!(retailMediaRecommenderExperiment !== null && retailMediaRecommenderExperiment !== void 0 && retailMediaRecommenderExperiment.bucket) && offer && category && ___EmotionJSX(SponsoredProductRecommender, {
    offerId: offer.id,
    category: category,
    fromProduct: fromProduct,
    sourceId: sourceId,
    newProductCard: isNewProductCard
  }), isRestrictedRecommender && brand && ___EmotionJSX(BrandRecommendedProducts, {
    productIds: [productId],
    brandName: brand.name,
    sellerName: isSellerRestrictedRecommender ? offer === null || offer === void 0 ? void 0 : (_offer$seller3 = offer.seller) === null || _offer$seller3 === void 0 ? void 0 : _offer$seller3.name : undefined,
    fromProduct: fromProduct,
    sourceId: sourceId,
    newProductCard: isNewProductCard
  }), !isRestrictedRecommender && ___EmotionJSX(React.Fragment, null, !(retailMediaRecommenderExperiment !== null && retailMediaRecommenderExperiment !== void 0 && retailMediaRecommenderExperiment.bucket) && ___EmotionJSX(PeopleAlsoViewed, {
    productIds: [productId],
    adult: adult,
    fromProduct: fromProduct,
    sourceId: sourceId,
    newProductCard: isNewProductCard
  }), (similarProductExperiment === null || similarProductExperiment === void 0 ? void 0 : similarProductExperiment.active) && ___EmotionJSX(SimilarProducts, {
    productId: productId,
    adult: adult,
    fromProduct: fromProduct,
    experimentBucket: similarProductExperiment.bucket,
    sourceId: sourceId,
    newProductCard: isNewProductCard
  }), ___EmotionJSX(RecommendedEvents, {
    productIds: [productId],
    sourceType: SOURCE_TYPE_PRODUCT_PAGE_RECOMMENDER_BI_EVENTS_V2,
    fromProduct: fromProduct,
    sourceId: sourceId
  }), (personalisedProductExperiment === null || personalisedProductExperiment === void 0 ? void 0 : personalisedProductExperiment.bucket) === 1 ? ___EmotionJSX(PersonalisedProductRecommender, {
    adult: adult,
    fromProduct: fromProduct,
    sourceId: sourceId,
    sourceType: SOURCE_TYPE_PRODUCT_PAGE_PERSONALISED_RECOMMENDER,
    newProductCard: isNewProductCard
  }) : null));
};